/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
.column-container
{
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
}

.header-title
{
  text-align: center;

  .underline
  {
    border-bottom: 3px solid #00b6e8;
    width: 50%;
    padding-top: 5px;
    margin-left: auto;
    margin-right: auto;
  }

  span
  {
    font-weight: bold;
    font-size: 20px;
  }
}

.required-question
{
  font-weight: bold;
}

.survey-questions
{
  .question-wrapper
  {
    margin-top: 2rem;
  }
}

.app-logo
{
  width: 30%;
  margin-left: auto;
  margin-right: auto;

  &::part(image)
  {
    border-radius: 20px;
  }
}

ion-button
{
  --border-radius: 1rem;
}

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
